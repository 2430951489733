import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Pricing.css";
import { PricingAPI } from "../../apiEndpoints";
import { useAppContext } from "../../AppContext";

function Pricing() {
  const { showLoader, hideLoader } = useAppContext();
  const [price, setPrice] = useState({
    originalPrice: 549,
    discountedPrice: 449,
  });

  useEffect(() => {
    const fetchPrice = async () => {
      showLoader();
      try {
        const fetchedPrice = await PricingAPI.get();
        setPrice(fetchedPrice);
      } catch (err) {
        console.error(err);
      } finally {
        hideLoader();
      }
    };

    fetchPrice();
  }, []);

  return (
    <div className="pricing" id="pricing" data-aos="flip-right">
      <h1>Fiyatlandırma</h1>
      <div className="card text-center">
        <div className="ribbon"></div>
        <h3 className="info">TEK SEFERLİK ÖDEME</h3>
        <div className="price">
          <h4>
            {price.discountedPrice} <span>USDT</span>
          </h4>
        </div>
        <div className="option">
          <ul>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Ömür boyu
              erişim
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Discorda
              katılım
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Canlı Destek
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Scalp
              Stratejileri
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Fon Yönetimi
              ile İlgili Stratejiler
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Finansal
              Piyaslarda Risk Yönetimi
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Sade ve Efektif
              Strateji Kurma
            </li>
            <li>
              <i className="fa fa-check" aria-hidden="true"></i> Price Action
              Konseptlerinin Efektif Kullanımları
            </li>
          </ul>
        </div>
        <Link to="/signUp">Kayıt Ol</Link>
      </div>
    </div>
  );
}

export default Pricing;
